import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import Typography from '@mui/material/Typography';
import React from 'react';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import PropTypes from 'prop-types';
import useCollectionDebtorOperationColumns from '../constants/collection-operation-columns';

const DebtorsOperations = ({ queryDocument, title }) => {
  const country = useGetCountryFromUrl();
  const columns = useCollectionDebtorOperationColumns();
  return (
    <FingoMainView
      id="debtors-operations-list"
      query={queryDocument}
      queryCustomVariables={{
        countryId: country?.id,
        collectionStatus: 'Pending collection',
      }}
      slots={{
        header: PaperHeader,
        table: FingoDataGrid,
      }}
      slotProps={{
        header: {
          viewTitle: title,
          finder: {
            searchPlaceHolder: 'Buscar deudor',
          },
        },
        table: {
          columns,
          noRowsMessage: () => <Typography>Sin deudores</Typography>,
          initialOrderBy: 'dateToPay',
          includeHeaders: [
            'name',
            'executive',
            'documentsCount',
            'dateToPay',
            'totalAmount',
            'lastAction',
            'actionsDrawer',
          ],
          rowsPerPageOptions: [15, 25, 50, 100],
          initialPageSize: 25,
        },
      }}
    />
  );
};

DebtorsOperations.propTypes = {
  queryDocument: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
};

export default DebtorsOperations;
