import { useMutation } from '@apollo/client';
import { offerDateToPaySelected } from '@fingo/lib/apollo/reactive-variables/invoices';
import { shoppingCartOffersVar } from '@fingo/lib/apollo/reactive-variables/shopping-cart';
import { formatMoneyWithSign } from '@fingo/lib/helpers/money';
import FileDownload from '@mui/icons-material/FileDownload';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DefaultDebtCalculatorGrid from '../../commercial/components/invoices-manager/headers-actions/simulation-grids/DefaultDebtCalculatorGrid';
import { buildInvoicesDataToSimulate } from '../../commercial/helpers';
import CALCULATE_DEFAULT_DEBT from '../graphql/mutations/calculate-default-debt';
import CREATE_DEFAULT_DEBT_FILE from '../graphql/mutations/create-default-debt-file';

const useAddDefaultDebtCalculatorSteps = (selectedDocuments) => {
  const [calculations, setCalculations] = useState([]);
  const [invoicesDataToSimulate, setInvoicesDataToSimulate] = useState([]);

  useEffect(() => {
    setInvoicesDataToSimulate(buildInvoicesDataToSimulate(selectedDocuments));
    const newSelectedInvoices = selectedDocuments.map(({ id }) => {
      const existingOffer = shoppingCartOffersVar().find(
        ({ invoiceId }) => invoiceId === id,
      );
      return existingOffer || { invoiceId: id, dateToPay: null };
    });
    shoppingCartOffersVar(newSelectedInvoices);
  }, [selectedDocuments?.length]);

  const resetFields = useCallback(() => {
    setCalculations([]);
    offerDateToPaySelected({});
  }, []);

  const [calculateDefaultDebt, { loading: loadingCalculation }] = useMutation(
    CALCULATE_DEFAULT_DEBT,
    {
      variables: {
        invoiceIds: invoicesDataToSimulate.map((inv) => inv.id),
        invoiceDates: invoicesDataToSimulate.map((inv) => inv.paymentDate),
      },
      onCompleted: (data) => {
        setCalculations([
          { name: 'Mora', value: formatMoneyWithSign(data.calculateDefaultDebtSimulation.defaultDebt) },
          { name: 'Monto', value: formatMoneyWithSign(data.calculateDefaultDebtSimulation.amount) },
        ]);
      },
    },
  );

  const [createDefaultDebtFile, { loading: loadingCreateDefaultDebtFile }] = useMutation(
    CREATE_DEFAULT_DEBT_FILE,
    {
      variables: {
        invoiceIds: invoicesDataToSimulate.map((inv) => inv.id),
        invoiceDates: invoicesDataToSimulate.map((inv) => inv.paymentDate),
        documentFormat: 'PDF',
      },
      onCompleted: (data) => {
        window.open(data.createDefaultDebtSimulationFile.url);
      },
    },
  );

  const steps = useMemo(() => [
    {
      title: 'Calculadora de Mora',
      fullWidth: true,
      maxWidth: 850,
      component: (
        <Stack sx={{
          alignItems: 'center',
          gap: 2,
        }}
        >
          <Box sx={{ maxHeight: 280, width: '100%', overflow: 'auto' }}>
            <DefaultDebtCalculatorGrid
              invoicesDataToSimulate={invoicesDataToSimulate}
              setInvoicesDataToSimulate={setInvoicesDataToSimulate}
              columns={[
                'folio',
                'operationDateToPay',
                'company_MasterEntity_Name',
                'amountWithIva',
                'paymentDate',
              ]}
            />
          </Box>
          <LoadingButton
            color="primary"
            variant="contained"
            label="Calcular"
            size="small"
            loading={loadingCalculation}
            onClick={calculateDefaultDebt}
          >
            Calcular
          </LoadingButton>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            columnSpacing={2}
            rowSpacing={1}
            sx={{
              width: '50%',
            }}
          >
            {calculations?.map((calc) => (
              <React.Fragment key={calc.name}>
                <Grid item xs={9}>
                  <Typography variant="small_2" fontWeight="bold" color="primary">
                    {calc.name}:
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="small_2">
                    {calc.value}
                  </Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
          {calculations.length > 0 && (
            <LoadingButton
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<FileDownload />}
              loading={loadingCreateDefaultDebtFile}
              onClick={createDefaultDebtFile}
            >
              Descargar PDF
            </LoadingButton>
          )}
        </Stack>
      ),
      backButton: {
        label: 'Atrás',
      },
      nextButton: {
        disabled: true,
        loading: loadingCalculation,
        label: '',
      },
    },
  ], [
    invoicesDataToSimulate,
    calculations,
    loadingCalculation,
    calculateDefaultDebt,
  ]);

  return [steps, resetFields];
};

export default useAddDefaultDebtCalculatorSteps;
