import React from 'react';
import { userHasRoutePermission } from '@fingo/lib/helpers/routes';
import { FingoPaper } from '@fingo/lib/components/layout';

import DescriptionOutlined from '@mui/icons-material/DescriptionOutlined';
import PersonAddAlt1 from '@mui/icons-material/PersonAddAlt1';
import AttachMoney from '@mui/icons-material/AttachMoney';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import { FACTORING_COLLECTION_MASTER_ENTITIES, ORDERING_COLLECTION_MASTER_ENTITIES, FACTORING_COLLECTION_OPERATIONS } from '@fingo/lib/graphql';
import { GET_PENDING_COLLECTION_MANAGERS_ACTIONS, GET_PENDING_ORDERING_COLLECTION_MANAGERS_ACTIONS } from '@fingo/lib/graphql/customers/queries';

import {
  CollectionInvoices,
  CollectionPurchaseOrders,
  CollectionSummary,
  DebtorAssignations,
  CreatePaymentPlan,
  Debtors,
  PaymentPlans,
  DebtorsOperations,
} from '../pages';

export const summaryTabs = (user) => [
  {
    id: 'summary-available',
    label: 'Facturas disponibles',
    path: 'available',
    component: <CollectionSummary />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'collection.view_collectionmanager'),
  },
];

export const debtorsTabs = (user, country) => [
  {
    id: 'debtors',
    label: 'Lista de deudores',
    path: 'debtors',
    component: <Debtors
      documentType="invoice"
      queryDocument={FACTORING_COLLECTION_MASTER_ENTITIES}
      title="Deudores factoring"
      additionalQueries={GET_PENDING_COLLECTION_MANAGERS_ACTIONS}
    />,
    disabled: false,
    showInSidebar: true,
    show: ['Chile'].includes(country?.name) && userHasRoutePermission(user, 'collection.view_collectionmanager'),
  },
  {
    id: 'operations-debtors',
    label: 'Deudores',
    path: 'operations-debtors',
    component: <DebtorsOperations
      queryDocument={FACTORING_COLLECTION_OPERATIONS}
      title="Deudores factoring"
    />,
    disabled: false,
    showInSidebar: true,
    show: ['Mexico'].includes(country?.name),
  },
  {
    id: 'collection-invoices',
    label: 'Lista de facturas',
    path: 'collection-invoices',
    component: <CollectionInvoices />,
    disabled: false,
    showInSidebar: true,
    show: ['Chile'].includes(country?.name) && userHasRoutePermission(user, 'collection.view_collectionmanager'),
  },
  {
    id: 'ordering-debtors',
    label: 'Clientes ordering',
    path: 'ordering-debtors',
    component: <Debtors
      documentType="ordering"
      queryDocument={ORDERING_COLLECTION_MASTER_ENTITIES}
      title="Clientes de ordering"
      additionalQueries={GET_PENDING_ORDERING_COLLECTION_MANAGERS_ACTIONS}
    />,
    disabled: false,
    showInSidebar: true,
    show: ['Chile'].includes(country?.name) && userHasRoutePermission(user, 'collection.view_collectionmanager'),
  },
  {
    id: 'collection-purchase-orders',
    label: 'Lista de órdenes de compra',
    path: 'collection-purchase-orders',
    component: <CollectionPurchaseOrders />,
    disabled: false,
    showInSidebar: true,
    show: ['Chile'].includes(country?.name) && userHasRoutePermission(user, 'collection.view_collectionmanager'),
  },
];

export const paymentPlansTabs = (user) => [
  {
    id: 'amortization-schedule',
    label: 'Planes de pago',
    path: 'schedules',
    component: <PaymentPlans />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'payment_plans.view_amortizationschedule'),
  },
];

export const debtorAssignationTabs = (user) => [
  {
    id: 'debtor-executive-assignation',
    label: 'Asignación de Deudores',
    path: 'main',
    component: <DebtorAssignations />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'payment_plans.view_amortizationschedule'),
  },
];

export const collectionRoutes = (user, country) => [
  {
    id: 'collection-summary',
    label: 'Inicio',
    path: 'summary',
    icon: <DescriptionOutlined />,
    component: <FingoPaper tabs={summaryTabs(user)} />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'collection.view_collectionmanager'),
  },
  {
    id: 'debtors',
    label: 'Deudores',
    path: 'debtors',
    icon: <AttachMoney />,
    component: <FingoPaper tabs={debtorsTabs(user, country)} />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'collection.view_collectionmanager'),
  },
  {
    id: 'amortization-schedule',
    label: 'Planes',
    path: 'payment-plans',
    icon: <CalendarMonth />,
    component: <FingoPaper tabs={paymentPlansTabs(user)} />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'payment_plans.view_amortizationschedule'),
  },
  {
    id: 'debtor-executive-assignation',
    label: 'Asignación de Deudores',
    path: 'debtor-assignation',
    icon: <PersonAddAlt1 />,
    component: <FingoPaper tabs={debtorAssignationTabs(user)} />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'payment_plans.view_amortizationschedule') && !['Mexico'].includes(country?.name),
  },
  {
    id: 'create-amortization-schedule',
    label: 'Planes de pago',
    path: 'create-amortization-schedule',
    component: <CreatePaymentPlan />,
    disabled: false,
    showInSidebar: false,
    show: userHasRoutePermission(user, 'payment_plans.add_amortizationschedule'),
  },
];
