import { DocumentList } from '@fingo/lib/components/lists';
import { FINGO_MASTER_ENTITY_ID } from '@fingo/lib/constants';
import { EXPORT_INVOICES } from '@fingo/lib/graphql';
import React from 'react';
import { LUCILA_COLLECTION_INVOICES } from '../graphql/queries';
import CollectionInvoicesColumns from './columns/CollectionInvoicesColumns';

const CollectionInvoices = () => (
  <DocumentList
    trackerId="COLLECTION_INVOICES"
    type="collection-invoices-list"
    headerTitle="Lista de facturas"
    countryFilter
    queryDocument={LUCILA_COLLECTION_INVOICES}
    initialOrderBy="collectionManagerRelation_CollectionPriority_Value"
    includeHeaders={[
      'folio-invoice-profile',
      'dateIssued',
      'receiver_Name',
      'company_profile',
      'amountWithIvaAndCreditNote',
      'dateToPay',
      'agreedDateToPay',
      'collectionManagerRelation_Collector',
      'siiStatus',
      'collectionManagerRelation_Priority',
      'ratificationActions',
      'collectionManagerRelation_CollectionPriority_Value',
      'actionsDrawer',
    ]}
    mobileHeaders={['folio', 'receiver_Name', 'cessionStatus']}
    allCompaniesOptions
    getRowId={(row) => row.id}
    queryExport={EXPORT_INVOICES}
    showExportInvoice
    showFilters
    defaultFilterProps={{
      showQuickFilters: true,
      buttonsToShow: ['requestingPlatform'],
      showValidSiiCredentialsFilter: false,
      showLightningFilter: false,
      showPreoffersFilters: false,
      showStatesFilter: false,
      showDatesFilter: false,
      showRefresh: true,
      showCompanyIssuerFilter: false,
      showCollectorExecutiveFilter: true,
    }}
    customVariables={{
      receiver_Rut: null,
      receiverId: null,
      inCollection: FINGO_MASTER_ENTITY_ID,
      dateIssued_Gte: null,
      dateIssued_Lte: null,
      status: ['Debt', 'Transfered'],
    }}
    initialPageSize={30}
    preColumns={CollectionInvoicesColumns()}
  />
);

export default CollectionInvoices;
