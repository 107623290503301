import React from 'react';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import { AMORTIZATION_SCHEDULE_NO_INSTALLMENTS, AMORTIZATION_SCHEDULE_PENDING_COLLECTION_MANAGERS } from '@fingo/lib/graphql/payment_plans/query';
import Typography from '@mui/material/Typography';
import useAmortizationScheduleColumns from '@fingo/lib/constants/useAmortizationScheduleColumns';
import { useGetCountryFromUrl } from '@fingo/lib/hooks';
import { PaymentPlanActions } from '../components/PaymentPlans';
import Installments from '../components/PaymentPlans/Installments';

const PaymentPlans = () => {
  const country = useGetCountryFromUrl();
  return (
    <FingoMainView
      id="conciliation-view"
      query={AMORTIZATION_SCHEDULE_NO_INSTALLMENTS}
      additionalQueries={{
        actionsDrawer: {
          query: AMORTIZATION_SCHEDULE_PENDING_COLLECTION_MANAGERS,
          queryCustomVariables: {
            status: 'ACTIVE',
            masterEntity_Country_Id: country?.graphqlId,
          },
          useIds: false,
        },
      }}
      queryCustomVariables={{
        status: 'ACTIVE',
        masterEntity_Country_Id: country?.graphqlId,
      }}
      slots={{
        header: PaperHeader,
        table: FingoTable,
        actions: PaymentPlanActions,
      }}
      slotProps={{
        header: {
          viewTitle: 'Planes de pago',
          finder: {
            searchPlaceHolder: 'Buscar',
          },
        },
        table: {
          columns: useAmortizationScheduleColumns(),
          includeHeaders: [
            'id',
            'masterEntity_Name',
            'interestRate',
            'defaultRate',
            'contentType_Model',
            'createdAt',
            'expirationDate',
            'lastAction',
            'actionsDrawer',
          ],
          noRowsMessage: () => <Typography>No hay planes de pago</Typography>,
          initialOrderBy: 'masterEntity_Name',
          checkboxSelection: false,
          rowsPerPageOptions: [10, 25, 50, 100],
          collapsible: true,
          isRowCollapsible: ({ row }) => !!row,
          collapseComponent: ({ row }) => (
            <Installments amortizationSchedule={row} />
          ),
        },
      }}
    />
  );
};

export default PaymentPlans;
